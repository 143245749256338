import { Description } from "@ethersproject/properties";
import * as React from "react";

import twitterIcon from "../../images/twitter-icon.svg";
import webIcon from "../../images/web-icon.svg";
import ytIcon from "../../images/yt-icon.svg";
import discordIcon from "../../images/discord-icon.svg";
import telegramIcon from "../../images/telegram-icon.svg";
import "./PartnerItem.scss";

export interface IPartnerItem {
  name: string;
  logoPath: string;
  description: string;
  moreDescription: string;
  key?: number;

  websiteUrl?: string;
  twitterUrl?: string;
  ytUrl?: string;
  discordUrl?: string;
  telegramUrl?: string;
}

interface ISocialLink {
  url?: string;
  icon?: string;
}
const SocialLink = ({ url, icon }: ISocialLink): JSX.Element =>
  (url && (
    <a
      className="social-link"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {" "}
      <img className="social-link__image" src={icon} alt="" />
    </a>
  )) || <></>;

export const PartnerItem = (partnerItem: IPartnerItem) => {
  return (
    <>
      {" "}
      <div className="item partner-item" data-value="1" key={partnerItem.key}>
        <figure className="partner-item__figure">
          <img src={partnerItem.logoPath} alt="" />
        </figure>
        <div className="partner-item__box radius theshadow">
          <div className="partner-item__header">
            <span className="partner-item__name">{partnerItem.name}</span>
            <div className="partner-item__description-wrapper">
              <span className="partner-item__description">
                {partnerItem.description}
              </span>
              <span className="partner-item__description">
                {partnerItem.moreDescription}
              </span>
            </div>
          </div>
          <div className="partner-item__social-box">
            <SocialLink url={partnerItem.ytUrl} icon={ytIcon} />
            <SocialLink url={partnerItem.websiteUrl} icon={webIcon} />
            <SocialLink url={partnerItem.twitterUrl} icon={twitterIcon} />
            <SocialLink url={partnerItem.discordUrl} icon={discordIcon} />
            <SocialLink url={partnerItem.telegramUrl} icon={telegramIcon} />
          </div>
        </div>
      </div>
    </>
  );
};
