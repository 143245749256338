import * as React from "react";
import type { HeadFC } from "gatsby";

import { useWeb3Provider, Web3Providers } from "../contexts/AppContext";

import { NavBar } from "../components/NavBar/NavBar";

import { PageHeader } from "../components/PageHeader/PageHeader";
import { Partners, PartnerType } from "../services/Partners";
import { PartnerItem } from "../components/PartnerItem/PartnerItem";
import { GoHomeButton } from "../components/Items/Buttons/Buttons";
import { Loader } from "../components/Loader";
import { DashboardNavbar } from "../components/DashboardNavbar/DashboardNavbar";
import { SEO } from "../components/SEO";
import { getLogoImage } from "../utils";

const IndexPage = ({ serverData }: { serverData: PartnerType[] }) => {
  return (
    <Web3Providers>
      <IndexPageComponent serverData={serverData} />
    </Web3Providers>
  );
};
const IndexPageComponent = ({ serverData }: { serverData: PartnerType[] }) => {
  const web3Provider = useWeb3Provider();
  const [partnersList, setPartnersList] =
    React.useState<PartnerType[]>(serverData);
  const [isLoading, setIsLoading] = React.useState(false);

  // React.useEffect(() => {
  //   setIsLoading(true);
  //   Partners.getPartners()
  //     .then(res => {
  //       if (res instanceof Error) throw res;
  //       console.log(res);
  //       setPartnersList(res);
  //       setIsLoading(false);
  //     })
  //     .catch(err => console.log("handle the error"));
  // }, []);
  return (
    <Web3Providers>
      <main className="sasiedzi-page">
        {web3Provider.accountDetails?.address ? (
          <DashboardNavbar />
        ) : (
          <NavBar />
        )}

        <PageHeader
          title="Poznaj naszych sąsiadów"
          subtitle="Ludzie, firmy, stowarzyszenia i pasjonaci NFT bez których nie bylibyśmy tymi samymi ThePolakami. Razem z nimi, ramię w ramię, dzielnie walczymy o popularyzację Web3 w Polsce."
        />

        {isLoading && <Loader />}

        <section className="neighbors-view__section meet-all-sasiedzi">
          <div className="neighbors-view__box">
            {partnersList &&
              partnersList.map((partner, index) => {
                return (
                  <div className="sasiad-details" key={partner.Name + index}>
                    <PartnerItem
                      name={partner.Name}
                      logoPath={getLogoImage(partner.Name)}
                      description={partner.WebsiteDescription}
                      moreDescription={partner.MoreDescription}
                      twitterUrl={partner.twitterUrl}
                      websiteUrl={partner.websiteUrl}
                      ytUrl={partner.ytUrl}
                      discordUrl={partner.discordUrl}
                      telegramUrl={partner.telegramUrl}
                    />
                  </div>
                );
              })}
          </div>
          <GoHomeButton />
        </section>
      </main>
    </Web3Providers>
  );
};

export default IndexPage;

// export const Head: HeadFC = () => <title>Sąsiedzi</title>;
export const Head: HeadFC = () => <SEO subtitle="Sąsiedzi" />;

export async function getServerData() {
  try {
    const partners = await Partners.getPartners();
    if (partners instanceof Error) throw partners;
    return {
      props: partners,
    };
  } catch (err) {
    console.error(err);
    return {};
  }
}
